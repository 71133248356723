<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Atualizar etapa #{{etapa.id}}</h3>
        <p style="text-align: left">Segue abaixo o formuário de atualização. Preencha com atenção.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar"  v-if="loading" @click="$router.push('/lista/etapa')">voltar</button>
    </div>
    <form class="formulario" autocomplete='off' v-if="loading" >
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="nome"
            name="nome"
            label="Nome Completo"
            :error-messages="erros.nome"
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="qtdedias"
            type="number"
            @mousedown="(e) => selecionarcampo(e)"
            name="qtdedias"
            :error-messages="erros.qtdedias"
            label="Quantidade de dias"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>      
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="12"
        >
            <Dropdown
              :multiple="false"
              :options="gadgets"
              id="gadget-select"
              v-model="gadget"
              :initialValue="gadget"
              data-cy="gadget"
              :border="'1px solid rgb(62, 62, 62)'"
              :borderradius="'0.3rem'"
              :name="'gadget'"
              :placeholder="'Gadget'"
              :error="erros.gadget"
              :onmousedown="(e) => selecionarcampo(e)"
              :ableToSearch="true"
              style="width:100%;"
            />
        </v-col>
        
      </v-row>  
      <v-row>
        
        <v-col
          class="d-flex"
          cols="12"
          sm="12"
        >
            <Dropdown
              :multiple="false"
              :options="destinos"
              id="destino-select"
              v-model="destino"
              :initialValue="destino"
              data-cy="destino"
              :border="'1px solid rgb(62, 62, 62)'"
              :borderradius="'0.3rem'"
              :placeholder="'Destino'"
              :name="'destino'"
              :error="erros.destino"
              :onmousedown="(e) => selecionarcampo(e)"
              style="width:100%;"
            />
        </v-col>
      </v-row> 
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          sm="12"
        >
            <Dropdown
              :multiple="false"
              :options="notificartipos"
              id="notificar-select"
              v-model="notificar"
              :initialValue="notificar"
              data-cy="notificar"
              :border="'1px solid rgb(62, 62, 62)'"
              :borderradius="'0.3rem'"
              :placeholder="'Notificar'"
              :error="erros.notificar"
              :name="'notificar'"
              :onmousedown="(e) => selecionarcampo(e)"
              style="width:100%;"
            />
        </v-col>
      </v-row>  
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="tituloemail"
            name="nome"
            label="Titulo do Email"
            outlined
          ></v-text-field>
        </v-col>
        
      </v-row>    
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="textoemail"
            name="nome"
            label="Texto do Email"
            outlined
          ></v-text-field>
        </v-col>
        
      </v-row> 
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="textobotaoacao"
            name="nome"
            label="Texto Botao Ação"
            outlined
          ></v-text-field>
        </v-col>
        
      </v-row> 

      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            @mousedown="(e) => selecionarcampo(e)"
            v-model="textourlbotao"
            name="nome"
            label="URL Botao Ação"
            outlined
          ></v-text-field>
        </v-col>
        
      </v-row> 
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="submeterform">
            atualizar etapa
          </v-btn>
        </v-col>
      </v-row>    
    </form>
    <form-skeleton v-if="!loading"/>
  </v-container>
  
</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import GadgetServico from '@/services/gadget.service.js'
import Dropdown from '@/components/Dropdown.vue';
import EtapaServico from '@/services/etapa.service.js'
import FormSkeleton from '../Loading/FormSkeleton.vue';
export default {
  components:{
    Dropdown,
    FormSkeleton
  },
  data(){
    
    return {
      loading: false,
      nome: null,
      qtdedias: null,
      gadgets: [],
      gadget: null,
      destinos: [{nome: 'Economy', value: 1}, {nome: 'Cliente', value: 2}],
      destino: null,
      reqloading: false,
      notificartipos: [{nome: 'Economy', value: 1}, {nome: 'Cliente', value: 2}, {nome: 'Todos', value: 3}],
      notificar:null,
      tituloemail:null,
      textoemail:null,
      textobotaoacao:null,
      textourlbotao:null,
      erros: {
        nome: '',
        qtdedias: '',
        gadget: '',
        destino: null,
        notificar: null,
      },
      etapa: {}
    }
  },
  methods: {
    async submeterform(e){
      e.preventDefault()
      if(this.validacao()){
        const dadosetapa = {
          id: this.etapa.id,
          nome: this.nome,
          qtdedias: this.qtdedias,
          idgadget: this.gadget,
          notificar: this.notificar,
          destino: this.destino,
          tituloemail:this.tituloemail,
          texto:this.textoemail,
          textobotaoacao:this.textobotaoacao,
          urlredirecionamento:this.textourlbotao
        }
        
        await EtapaServico.criar(dadosetapa)
          .then(res => {
            if(res.status === 200){
              alertar('success', '=)', 'Etapa atualizada com sucesso!')
            }
          })
          .catch(e => {
            console.log('erro em atualizar etapa', e)
            alert('warning', '=/', 'Falha na ataulização da etapa!')
          })
      }
    },
    validacao(){
      let validado = true
      
      if(!this.nome || this.nome.trim() === ''){
        // alert('a 1')
        validado = false
        this.erros.nome = 'O campo nome é obrigatório.'
      }

      
      if(!this.qtdedias || this.qtdedias == 0){
        // alert('a 2')
        validado = false
        this.erros.qtdedias = 'O campo Qtde Dias é obrigatório.'
      }
      // if(!this.gadget || this.gadget == null){
      //   validado = false
      //   this.erros.gadget = 'O campo e-mail é obrigatório.'
      // }

      
      if(!this.destino || this.destino == null){
        // alert('a 3')
        validado = false
        this.erros.destino = 'O campo destino é obrigatório.'
      }

      
      if(!this.notificar || this.notificar == null){
        // alert('a 4')
        validado = false
        this.erros.notificar = 'O campo notificacao é obrigatório.'
      }

      // alert('a 5')
      return validado
    },
    selecionarcampo(e){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = null
      }
    },
    async requisitarGadgets(){
      this.gadgets = await GadgetServico.buscar().then(res => {

        // alert(JSON.strin)
        // return res.data
        return res.data.map(gadget => ({value:gadget.id, nome:gadget.nome}))
      })

      this.gadgets.push({value: 0, nome: 'Sem Gadget'})
    },
    async requisitarEtapa(id){
      this.etapa = await EtapaServico.buscar({id: id}).then(res=>res.data[0])
    }
  },
  async mounted(){
    const {id} = this.$route.params
    await this.requisitarGadgets()
    await this.requisitarEtapa(id)
    // alert(JSON.stringify(this.etapa,null,2  ))
    this.id = this.etapa.id
    this.nome = this.etapa.nome
    this.qtdedias = this.etapa.qtdedias
    this.gadget = this.etapa.idgadget
    this.destino = this.etapa.destino
    this.notificar = this.etapa.notificar
    this.tituloemail = this.etapa.tituloemail
    this.textoemail = this.etapa.texto
    this.textobotaoacao = this.etapa.textobotaoacao
    this.urlredirecionamento = this.etapa.urlredirecionamento
    this.loading = true
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  margin-top: 1em;
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}
.formulario{
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px 20px #d9d9d9;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
</style>