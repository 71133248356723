<template>
    <AtualizarEtapa />
</template>

<script>
import AtualizarEtapa from '@/components/Etapa/AtualizarEtapa'
export default {
  components: {AtualizarEtapa},
  data(){
    return{}
  }
}
</script>

<style>

</style>